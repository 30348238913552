import React from "react";
import Layout from "../components/layout";
import Section from "../components/section";

const PrivacyPolicyPage = () => {
    return (
        <Layout>
            <div className="mt-10">
                <h1 className="text-4xl text-center font-bold">Privacy Policy</h1>
            </div>
            <div className="container prose mt-5 max-w-7xl mx-auto px-8 xl:px-0 mb-[72px]">
                <h3>Last revised: November 16, 2022</h3>
                <p>
                    Data Fusion Specialists and Coeus care about your privacy.&nbsp; For this reason,
                    we collect and use personal data only as it might be needed for us to
                    deliver to you our world-class products, services, websites and mobile
                    applications (collectively, our “Services”). Your personal data includes
                    information such as:
                </p>

                <ul>
                    <li>Name</li>
                    <li>Address</li>
                    <li>Telephone number</li>
                    <li>Date of birth</li>
                    <li>Email address</li>
                    <li>Company</li>
                    <li>
                        Other data collected that could directly or indirectly identify you.
                    </li>
                </ul>

                <p>
                    Our Privacy Policy is intended to describe to you how and what data we collect, and how and why we use your personal data. It also describes options we provide for you to access, update, or otherwise take control of any personal data shared with us.
                </p>

                <p>
                    If at any time you have questions about our practices or any of your
                    rights described below, you may reach us by contacting us at&nbsp;
                    <a href="mailto:privacy@coeus.chat">privacy@coeus.chat</a>. This inbox is
                    actively monitored and managed so that we can deliver an experience that
                    you can confidently trust.
                </p>

                <h2>What personal data we collect and why we collect it</h2>

                <p>
                    <strong>Media</strong>
                </p>

                <p>
                    If you upload images to the website, you should avoid uploading images
                    with embedded location data (EXIF GPS) included. Visitors to the website
                    can download and extract any location data from images on the website.
                </p>

                <p>
                    <strong>Embedded content from other websites</strong>
                </p>

                <p>
                    Articles on this site may include embedded content (e.g., videos, images,
                    articles). Embedded content from other websites behaves in the
                    exact same way as if the visitor has visited the other website.
                </p>

                <p>
                    These websites may collect data about you, use cookies, embed additional
                    third-party tracking, and monitor your interaction with that embedded
                    content, including tracking your interaction with the embedded content
                    if you have an account and are logged in to that website.
                </p>

                <p>
                    <strong>What information do we collect?</strong>
                </p>

                <p>
                    We collect information so that we can provide the best possible
                    experience when you utilize our Services.&nbsp; Much of what you likely
                    consider personal data is collected directly from you when you:
                </p>

                <p>
                    (1)&nbsp;&nbsp;&nbsp; complete contact forms or request newsletters or
                    other information from us; or
                </p>

                <p>
                    (2)&nbsp;&nbsp;&nbsp; participate in contests and surveys, apply for a
                    job, or otherwise participate in activities we promote that might
                    require information about you.
                </p>

                <p>
                    However, we also collect additional information when delivering our
                    Services to you to ensure necessary and optimal performance.&nbsp; These
                    methods of collection may not be as obvious to you, so we wanted to
                    highlight and explain below a bit more about what these might be (as
                    they vary from time to time) and how they work:
                </p>

                <p>
                    <strong>Cookies</strong>&nbsp;<strong>and similar technologies</strong>
                    &nbsp;on our websites and our mobile applications allow us to track your
                    browsing behavior, links clicked, items purchased, your device type; and
                    to collect various data, including analytics, about how you use and
                    interact with our Services. This allows us to provide you with more
                    relevant product offerings, a better experience on our sites and mobile
                    applications, and to collect, analyze and improve the performance of our
                    Services. We may also collect your location (IP address) so that we can
                    personalize our Services.{" "}
                </p>

                <p>
                    <strong>Data about Usage of Services</strong>&nbsp;is automatically
                    collected when you use and interact with our Services, including
                    metadata, log files, cookie/device IDs and location information. This
                    information includes specific data about your interactions with the
                    features, content and links (including those of third-parties, such as
                    social media plugins) contained within the Services; Internet Protocol
                    (IP) address; browser type and settings, the date and time the Services
                    were used; information about browser configuration and plugins; language
                    preferences and cookie data; information about devices accessing the
                    Services, including type of device, what operating system is used,
                    device settings, application IDs, unique device identifiers and error
                    data. Some of this data collected might be capable of and be used to
                    approximate your location.
                </p>

                <p>
                    <strong>Supplemented Data&nbsp;</strong>may be received about you from
                    other sources, including publicly available databases or third parties
                    from whom we have purchased data. We may combine this data
                    with information we already have about you so that we can update, expand,
                    and analyze the accuracy of our records; identify new customers; and
                    provide products and services that may be of interest to you.&nbsp; If
                    you provide us personal information about others, or if others give us
                    your information, we will only use that information for the specific
                    reason for which it was provided to us.
                </p>

                <p>
                    <strong>How we utilize information.</strong>
                </p>

                <p>
                    We strongly believe in both minimizing the data we collect and limiting
                    its use and purpose to only that (1)&nbsp;
                    <em>for which we have been given permission</em>, (2)&nbsp;
                    <em>
                        as necessary to deliver the Services you purchase or interact with
                    </em>
                    , or (3)&nbsp;
                    <em>
                        as we might be required or permitted for legal compliance or other
                        lawful purposes
                    </em>
                    . These uses include:
                </p>

                <p>
                    <strong>
                        Delivering, improving, updating and enhancing the Services we provide
                        to you.&nbsp;&nbsp;
                    </strong>
                    We collect various information relating to your purchase, use and/or
                    interactions with our Services. We utilize this information to:
                </p>

                <ul>
                    <li>
                        Improve and optimize the operation and performance of our Services
                        (again, including our websites and mobile applications)
                    </li>
                    <li>
                        Diagnose problems with and identify any security risks, errors, or
                        needed enhancements to the Services
                    </li>
                    <li>Detect and prevent fraud and abuse of our Services and systems</li>
                    <li>Collecting aggregate statistics about use of the Services</li>
                    <li>
                        Understand and analyze how you use our Services and what products and
                        services are most relevant to you.
                    </li>
                </ul>

                <p>
                    Much of the data collected is aggregated or statistical data
                    about how individuals use our Services, and is not linked to any
                    personal data, but to the extent it is itself personal data, or is
                    linked or linkable to personal data, we treat it accordingly.
                </p>

                <p>
                    <strong>Sharing with trusted third parties.&nbsp;</strong>We may share
                    your personal data with affiliated companies within our corporate
                    family, with third parties with which we have partnered to allow you to
                    integrate their services into our own Services, and with trusted third
                    party service providers as necessary for them to perform services on our
                    behalf, such as:
                </p>

                <ul>
                    <li>Processing credit card payments</li>
                    <li>Serving advertisements</li>
                    <li>Conducting contests or surveys</li>
                    <li>Performing analysis of our Services and customers demographics</li>
                    <li>Communicating with you, such as by email or survey delivery</li>
                    <li>Customer relationship management.</li>
                </ul>

                <p>
                    We only share your personal data as necessary for any third party to
                    provide the services as requested or as needed on our behalf. These
                    third parties (and any subcontractors) are subject to strict data
                    processing terms and conditions and are prohibited from utilizing,
                    sharing, or retaining your personal data for any purpose other than as
                    they have been specifically contracted for (or without your consent).
                </p>

                <p>
                    <strong>Communicating with you.&nbsp;</strong>We may contact you
                    directly or through a third-party service provider regarding products or
                    services for which you have signed up or purchased from us, such as necessary to
                    deliver transactional or service-related communications. We may also
                    contact you with offers for additional services we think you’ll find
                    valuable if you give us consent, or where allowed based upon legitimate
                    interests. You don’t need to provide consent as a condition to purchase
                    our goods or services. These contacts may include:
                </p>

                <ul>
                    <li>Email</li>
                    <li>Text (SMS) messages</li>
                    <li>Telephone calls</li>
                    <li>Automated phone calls or text messages.</li>
                </ul>

                <p>
                    You may also update your subscription preferences with respect to
                    receiving communications from us and/or our partners by signing into
                    your account and visiting “Account Settings” page.
                </p>

                <p>
                    If we collect information from you in connection with a co-branded
                    offer, it will be clear at the point of collection who is collecting the
                    information and whose privacy policy applies. In addition, it will
                    describe any choice options you have in regards to the use and/or
                    sharing of your personal data with a co-branded partner, as well as how
                    to exercise those options.
                </p>

                <p>
                    If you make use of a service that allows you to import contacts (e.g.,
                    using email marketing services to send emails on your behalf), we will
                    only use the contacts and any other personal information for the
                    requested service. If you believe that anyone has provided us with your
                    personal information and you would like to request that it be removed
                    from our database, please contact us at&nbsp;
                    <a href="mailto:privacy@coeus.chat">privacy@coeus.chat.</a>
                </p>

                <p>
                    <strong>Transfer of personal data abroad</strong>.&nbsp; If you utilize
                    our Services from a country other than the country where our servers are
                    located, your communications with us may result in transferring your
                    personal data across international borders. Also, when you call us or
                    initiate a chat, we may provide you with support from one of our global
                    locations outside your country of origin. In these cases, your personal
                    data is handled according to this Privacy Policy.
                </p>

                <p>
                    <strong>
                        Compliance with legal, regulatory and law enforcement requests
                    </strong>
                    . We cooperate with government and law enforcement officials and private
                    parties to enforce and comply with the law. We will disclose any
                    information about you to government or law enforcement officials or
                    private parties as we, in our sole discretion, believe necessary or
                    appropriate to respond to claims and legal process (such as subpoena
                    requests), to protect our property and rights or the property and rights
                    of a third party, to protect the safety of the public or any person, or
                    to prevent or stop activity we consider to be illegal or unethical.
                </p>

                <p>
                    To the extent we are legally permitted to do so, we will take reasonable
                    steps to notify you in the event that we are required to provide your
                    personal information to third parties as part of legal process. We will
                    also share your information to the extent necessary to comply with ICANN
                    or any ccTLD rules, regulations and policies when you register a domain
                    name with us.
                </p>

                <p>
                    <strong>Website analytics</strong>.&nbsp; We use multiple web analytics
                    tools provided by service partners such as ezMonitorIT, Google
                    Analytics, MixPanel and Singular to collect information about how you
                    interact with our website or mobile applications, including what pages
                    you visit; what site you visited prior to visiting our website; how much
                    time you spend on each page; what operating system and web browser you
                    use; and network and IP information. We use the information provided by
                    these tools to improve our Services. These tools place persistent
                    cookies in your browser to identify you as a unique user the next time
                    you visit our website. Each cookie cannot be used by anyone other than
                    the service provider (ex: Google for Google Analytics). The information
                    collected from the cookie may be transmitted to and stored by these
                    service partners on servers in a country other than the country in which
                    you reside. Though information collected does not include personal data
                    such as name, address, billing information, etc., the information
                    collected is used and shared by these service providers in accordance
                    with their individual privacy policies. You can control the technologies
                    we use by managing your settings through our cookie policy or the
                    ‘cookie banners” that may be presented (depending on URL of website
                    visited) when you first visit our webpages, or by utilizing settings in
                    your browser or third-party tools.
                </p>

                <p>
                    <strong>Third-party websites</strong>.&nbsp; Our website and our mobile
                    applications contain links to third-party websites. We are not
                    responsible for the privacy practices or the content of third-party
                    sites.&nbsp; Please read the privacy policy of any website you visit.
                </p>

                <p>
                    If you have an unresolved privacy or data use concern that we have not
                    addressed satisfactorily, Please contact us&nbsp;
                    <a href="mailto:privacy@coeus.chat">privacy@coeus.chat</a>
                </p>

                <p>
                    <strong>How you can access, update, or delete your data.</strong>
                </p>

                <p>
                    You can make a request to update or delete your personal data at&nbsp;
                    <a href="mailto:privacy@coeus.chat">privacy@coeus.chat</a>. If data is
                    necessary for the products or services you have purchased, the request
                    will be honored only to the extent it is no longer necessary for any
                    Services purchased or required for our legitimate business purposes or
                    legal or contractual record keeping requirements.
                </p>

                <p>
                    <strong>How we secure, store and retain your data.</strong>
                </p>

                <p>
                    We follow generally accepted standards to store and protect the personal
                    data we collect, both during transmission and once received and stored,
                    including utilization of encryption where appropriate.
                </p>

                <p>
                    We retain personal data only for as long as necessary to provide the
                    Services you have requested and thereafter for a variety of legitimate
                    legal or business purposes. These might include retention periods:
                </p>

                <ul>
                    <li>
                        mandated by law, contract or similar obligations applicable to our
                        business operations;
                    </li>
                    <li>
                        for preserving, resolving, defending, or enforcing our
                        legal/contractual rights; or
                    </li>
                    <li>
                        needed to maintain adequate and accurate business and financial
                        records.
                    </li>
                </ul>

                <p>
                    If you have any questions about the security or retention of your
                    personal data, you can contact us at&nbsp;
                    <a href="mailto:privacy@coeus.chat">privacy@coeus.chat</a>
                </p>

                <p>
                    <strong>
                        <em>‘Do Not Track’</em>
                    </strong>
                    <strong>&nbsp;notifications.</strong>
                </p>

                <p>
                    Some browsers allow you to automatically notify websites you visit not
                    to track you using a “Do Not Track” signal. There is no consensus among
                    industry participants as to what “Do Not Track” means in this context.
                    Like many websites and online services, we currently do not alter our
                    practices when we receive a “Do Not Track” signal from a visitor’s
                    browser. To find out more about “Do Not Track,” you may wish to
                    visit&nbsp;<a href="http://www.allaboutdnt.com/">www.allaboutdnt.com</a>
                    .
                </p>

                <p>
                    <strong>Age restrictions.</strong>
                </p>

                <p>
                    Our Services are available for purchase only for those over the age of
                    18.&nbsp; Our Services are not targeted to, intended to be consumed by
                    or designed to entice individuals under the age of 18. If you know of or
                    have reason to believe anyone under the age of 18 has provided us with
                    any personal data, please contact us.
                </p>

                <p>
                    <strong>Changes in our Privacy Policy.</strong>
                </p>

                <p>
                    We reserve the right to modify this Privacy Policy at any time. If we
                    decide to change our Privacy Policy, we will post those changes to this
                    Privacy Policy and any other places we deem appropriate, so that you are
                    aware of what information we collect, how we use it, and under what
                    circumstances, if any, we disclose it. If we make material changes to
                    this Privacy Policy, we will notify you here, by email, or by means of a
                    notice on our home page, at least thirty (30) days prior to the
                    implementation of the changes.
                </p>

                <p>
                    <strong>Where we send your data</strong>
                </p>

                <p>
                    Contact form submissions may be checked through an automated spam
                    detection service.
                </p>

                <p>
                    <strong>Data Protection Authority.</strong>
                </p>

                <p>
                    If you are a resident of the European Economic Area (EEA) and believe we
                    maintain your personal data subject to the General Data Protection
                    Regulation (GDPR), you may direct questions or complaints to our lead
                    supervisory authority, the UK’s&nbsp;Information Commissioner’s
                    Office,&nbsp;as noted below:
                </p>

                <p>
                    <a href="http://www.ico.org.uk/">www.ico.org.uk</a>
                </p>

                <p>Information Commissioner’s Office</p>

                <p>
                    Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF, United Kingdom
                </p>

                <p>Phone: 0303 123 1113</p>

                <p>
                    <strong>Contact us.</strong>
                </p>

                <p>
                    If you have any questions, concerns or complaints about our Privacy
                    Policy, our practices or our Services, you may contact our Office at&nbsp;
                    <a href="mailto:privacy@coeus.chat">privacy@coeus.chat</a>.
                </p>

                <p>
                    <strong>
                        <em>
                            We will respond to all requests, inquiries, or concerns within thirty
                            (30) days.
                        </em>
                    </strong>
                </p>
            </div>
        </Layout>
    );
};

export default PrivacyPolicyPage;
